.tag{
  cursor: pointer;
  transition: all 0.3s linear;
}
.tag:hover{
  color: #f99429!important;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  text-align: center;
}

.box .row.content {
  flex: 1 1 auto;
  overflow: hidden;
}

.box .row.footer {
  flex: 0 1 40px;
}

.modalContent{
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .modalContent{
    height: 50%;
  }
}

:global .carousel.slide{
  height: 100%;
}
:global .carousel-inner{
  height: 100%!important;
}
:global .carousel-item{
  height: 100%!important;
}
:global .carousel-caption h3{
  text-shadow: 1px 1px 5px black;
}
:global .carousel-caption p{
  text-shadow: 1px 1px 3px black;
}
:global .carousel-control-next-icon{
  filter: drop-shadow(1px 1px 3px black);
}
:global .carousel-control-prev-icon{
  filter: drop-shadow(1px 1px 3px black);
}
:global .carousel-indicators li{
  filter: drop-shadow(1px 1px 3px black);
}