.input{
  width: 100%;
  padding: 15px 5px;
  background: none;
  border: 0;
  border-bottom: 1px solid white;
  outline: 0;
  color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.input:focus{
  border-color: #f99429;
}