.Header{
  position: fixed;
  top: 0px;
  height: 74px;
  width: 100%;
  padding: 25px 35px;
  background: linear-gradient(180deg, rgba(2,0,36,0.8) 0%, rgba(22,28,45,0.8) 70%, rgba(0,255,233,0) 100%);
  z-index: 10;
  font-weight: bold;
  filter: drop-shadow(0px 0px 2px gray);

  .option{
    float: right;
    margin-left: 30px;
    margin-right: 30px;
    cursor: pointer;
    text-transform: lowercase;
    transition: all 0.3s ease-in-out;
  }

  .option:hover{
    color: #f99429;
  }

  .mobileOption{
    cursor: pointer;
    text-transform: lowercase;
    padding: 5px 0px;
    transition: all 0.3s ease-in-out;
  }

  .mobileOption:hover{
    color: #f99429;
  }

  .mobileMenu{
    display: none;
  }
  .desktopMenu{
    display: block;
  }
  @media only screen and (max-width: 850px) {
    .mobileMenu{
      display: block;
    }
    .desktopMenu{
      display: none;
    }
  }
}